import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/aftercare-templates": [~3],
		"/aftercare-templates/[id]": [~4],
		"/consent-templates": [~5],
		"/consent-templates/[id]": [~6],
		"/login": [7],
		"/patients": [8],
		"/patients/[id]": [9],
		"/patients/[id]/consent-forms/[formId]": [~10],
		"/patients/[id]/medical-history/[medicalHistoryId]": [~11],
		"/patients/[id]/medical-notes/[medicalNoteId]": [~12],
		"/patients/[id]/photos": [~13],
		"/testimonials/[id]": [~17],
		"/test": [14],
		"/test/messaging": [15],
		"/test/messaging/compose": [16],
		"/treatment-plan": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';