import * as Sentry from '@sentry/sveltekit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://681c7ae5f9652a15d78d0f994db94bd8@o4507881577512960.ingest.de.sentry.io/4507881602416720',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [Sentry.replayIntegration()],
	environment: import.meta.env.VITE_ENVIRONMENT
});

export const handleError = Sentry.handleErrorWithSentry();
